<template lang="pug">
  b-card.manager-account-address-form.card-white.card-no-gutters.shadow(no-body)
    b-card-header.bg-transparent.border-0
      h5.m-0
        | {{ $t('activerecord.models.address.one') }}
    b-form.form-wc.form-account-address-form.mt-sm-3(
      @submit.stop.prevent='formSubmit'
      @input.stop.prevent='formChange'
      @reset.stop.prevent='formReset')
      b-card-body

        wc-forms-alerts(v-if='formShowErrors' :errors='form.errors.base')

        transition(name='fade')
          fieldset(v-if='formShow' :disabled='formDisable')
            b-form-group#form-address-name(
              :label="$t('activerecord.attributes.shared.name')"
              label-for='address-name'
              label-cols-md='2'
              label-align-md='right')
              b-form-input#address-name.form-input-first.shadow-sm.is-required(
                name='name'
                type='text'
                autocomplete='name'
                :placeholder="$t('address.placeholders.name_account')"
                :state='formValidationState($v.form.data.attributes.name)'
                @focus.native='formChange'
                v-model='$v.form.data.attributes.name.$model'
                aria-describedby='address-name-feedback'
                trim)
              wc-forms-if#address-name-feedback(
                extra-class='ml-feedback'
                :attribute='$v.form.data.attributes.name'
                :remote="formRemoteInvalidFeedback('name')"
                :validators='{ required: {}, maxLength: {} }')

            b-form-group#form-address-street(
              :label="$t('activerecord.attributes.address.street')"
              label-for='address-street'
              label-cols-md='2'
              label-align-md='right')
              b-form-input#address-street.form-input-middle.shadow-sm.is-required(
                name='street'
                type='text'
                autocomplete='street'
                :placeholder="$t('address.placeholders.street')"
                :state='formValidationState($v.form.data.attributes.street)'
                @focus.native='formChange'
                v-model='$v.form.data.attributes.street.$model'
                aria-describedby='address-street-feedback'
                trim)
              wc-forms-if#address-street-feedback(
                extra-class='ml-feedback'
                :attribute='$v.form.data.attributes.street'
                :remote="formRemoteInvalidFeedback('street')"
                :validators='{ required: {}, maxLength: {} }')

            b-form-group#form-address-number(
              :label="$t('activerecord.attributes.address.number')"
              label-for='address-number'
              label-cols-md='2'
              label-align-md='right')
              b-form-input#address-number.form-input-middle.shadow-sm.is-required.w-50(
                name='number'
                type='text'
                autocomplete='number'
                :placeholder="$t('address.placeholders.number')"
                :state='formValidationState($v.form.data.attributes.number)'
                @focus.native='formChange'
                v-model='$v.form.data.attributes.number.$model'
                aria-describedby='address-number-feedback'
                trim)
              wc-forms-if#address-number-feedback(
                extra-class='ml-feedback'
                :attribute='$v.form.data.attributes.number'
                :remote="formRemoteInvalidFeedback('number')"
                :validators='{ required: {}, maxLength: {} }')

            b-form-group#form-address-complement(
              :label="$t('activerecord.attributes.address.complement')"
              label-for='address-complement'
              label-cols-md='2'
              label-align-md='right')
              b-form-input#address-complement.form-input-middle.shadow-sm(
                name='complement'
                type='text'
                autocomplete='complement'
                :placeholder="$t('address.placeholders.complement')"
                :state='formValidationState($v.form.data.attributes.complement)'
                @focus.native='formChange'
                v-model='$v.form.data.attributes.complement.$model'
                aria-describedby='address-complement-feedback'
                trim)
              wc-forms-if#address-complement-feedback(
                extra-class='ml-feedback'
                :attribute='$v.form.data.attributes.complement'
                :remote="formRemoteInvalidFeedback('complement')"
                :validators='{ maxLength: {} }')

            b-form-group#form-address-city(
              :label="$t('activerecord.attributes.address.city')"
              label-for='address-city'
              label-cols-md='2'
              label-align-md='right')
              b-form-input#address-city.form-input-middle.shadow-sm.is-required(
                name='city'
                type='text'
                autocomplete='city'
                :placeholder="$t('address.placeholders.city')"
                :state='formValidationState($v.form.data.attributes.city)'
                @focus.native='formChange'
                v-model='$v.form.data.attributes.city.$model'
                aria-describedby='address-city-feedback'
                trim)
              wc-forms-if#address-city-feedback(
                extra-class='ml-feedback'
                :attribute='$v.form.data.attributes.city'
                :remote="formRemoteInvalidFeedback('city')"
                :validators='{ required: {}, maxLength: {} }')

            b-form-group#form-address-zip_code(
              :label="$t('activerecord.attributes.address.zip_code')"
              label-for='address-zip_code'
              label-cols-md='2'
              label-align-md='right')
              b-form-input#address-zip_code.form-input-middle.shadow-sm.is-required.w-50(
                name='zip_code'
                type='text'
                autocomplete='zip_code'
                :placeholder="$t('address.placeholders.zip_code')"
                :state='formValidationState($v.form.data.attributes.zip_code)'
                @focus.native='formChange'
                v-model='$v.form.data.attributes.zip_code.$model'
                aria-describedby='address-zip_code-feedback'
                trim)
              wc-forms-if#address-zip_code-feedback(
                extra-class='ml-feedback'
                :attribute='$v.form.data.attributes.zip_code'
                :remote="formRemoteInvalidFeedback('zip_code')"
                :validators='{ required: {}, maxLength: {} }')

            b-form-group#form-address-region(
              :label="$t('activerecord.attributes.address.region')"
              label-for='address-region'
              label-cols-md='2'
              label-align-md='right')
              b-form-input#address-region.form-input-middle.shadow-sm.is-required(
                name='region'
                type='text'
                autocomplete='region'
                :placeholder="$t('address.placeholders.region')"
                :state='formValidationState($v.form.data.attributes.region)'
                @focus.native='formChange'
                v-model='$v.form.data.attributes.region.$model'
                aria-describedby='address-region-feedback'
                trim)
              wc-forms-if#address-region-feedback(
                extra-class='ml-feedback'
                :attribute='$v.form.data.attributes.region'
                :remote="formRemoteInvalidFeedback('region')"
                :validators='{ required: {}, maxLength: {} }')

            b-form-group#form-address-country_code(
              :label="$t('activerecord.attributes.address.country_code')"
              label-for='address-country_code'
              label-cols-md='2'
              label-align-md='right')
              multiselect#address-country_code.form-input-last.shadow-sm.custom-select.is-required(
                name='country_code'
                v-model='addressCountryCodeSelected'
                :options='addressCountryCodeOptions'
                :class='formValidationClass($v.form.data.attributes.country_code)'
                :close-on-select='true'
                :clear-on-select='false'
                :placeholder="$t('address.placeholders.country_code')"
                label='label'
                track-by='value'
                :selectLabel="$t('shared.forms.multiselect.select_label')"
                :selectedLabel="$t('shared.forms.multiselect.selected_label')"
                :deselectLabel="$t('shared.forms.multiselect.deselect_label')"
                :limitText="count => $t('shared.forms.multiselect.limit_text', { count: count })"
                @input='formSelectOption')
                span(slot='noResult')
                  | {{ $t('shared.forms.multiselect.no_result') }}
                span(slot='noOptions')
                  | {{ $t('shared.forms.multiselect.no_options') }}
              wc-forms-if#address-country_code-feedback(
                extra-class='ml-feedback'
                :attribute='$v.form.data.attributes.country_code'
                :remote="formRemoteInvalidFeedback('country_code')"
                :validators='{ required: {} }')

      b-card-footer.border-0.bg-white
        wc-forms-buttons#form-address-buttons(:disabled='formDisable || $v.form.data.$invalid')

    loading(:active.sync='isLoading' :can-cancel-esc='true' :on-cancel='onCancel')
</template>

<script>
import WcFormsButtons from '@components/shared/forms/WcFormsButtons'
import apiAddress from '@services/api/account/address'
import { Form, mapSelects } from '@common/form'
import { required, maxLength } from 'vuelidate/lib/validators'

export default {
  name: 'manager-account-address-form',
  mixins: [Form],
  components: {
    WcFormsButtons,
  },
  computed: {
    ...mapSelects([
      {
        name: 'addressCountryCode',
        default: 'attributes.country_code',
        attribute: 'attributes.country_code',
        collection: { data: 'countries.data', key: 'id', value: 'attributes.name' },
      },
    ]),
    apiParams() {
      return {
        get: [],
        update: [
          {},
          { [this.apiModel]: this.formSubmitData(this.$getDeep(this.form, 'data.attributes')) },
        ],
      }
    },
  },
  validations() {
    return {
      form: {
        data: {
          attributes: {
            name: {
              required,
              maxLength: maxLength(this.$wc.conf.limits.max_short_field),
              remote: () => this.formRemoteValid('name'),
            },
            street: {
              required,
              maxLength: maxLength(this.$wc.conf.limits.max_address_street),
              remote: () => this.formRemoteValid('street'),
            },
            number: {
              required,
              maxLength: maxLength(this.$wc.conf.limits.max_address_number),
              remote: () => this.formRemoteValid('number'),
            },
            complement: {
              maxLength: maxLength(this.$wc.conf.limits.max_address_complement),
              remote: () => this.formRemoteValid('complement'),
            },
            city: {
              required,
              maxLength: maxLength(this.$wc.conf.limits.max_address_city),
              remote: () => this.formRemoteValid('city'),
            },
            zip_code: {
              required,
              maxLength: maxLength(this.$wc.conf.limits.max_address_zip_code),
              remote: () => this.formRemoteValid('zip_code'),
            },
            region: {
              required,
              maxLength: maxLength(this.$wc.conf.limits.max_address_region),
              remote: () => this.formRemoteValid('region'),
            },
            country_code: {
              required,
              remote: () => this.formRemoteValid('country_code'),
            },
          },
        },
      },
    }
  },
  data() {
    return {
      apiBase: apiAddress,
      apiModel: 'address',
    }
  },
}
</script>
